import Header from '../components/header'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../components/footer'

export default function Layout() {
  return (
    <div className='layout-provider flex flex-col h-full'>
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}
