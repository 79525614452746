import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import CommonSection from "../../../components/common-section/CommonSection";
import { getNews, getSingleOrg } from "../../../redux/actions/actions";
// import { ReactComponent as Phone } from "../../../assets/phone-icon.svg";
// import Mail from "../../../assets/mail.svg";

const SertificationOrgs = () => {
	const { slug } = useParams();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { singleOrg } = useSelector((state) => state.singleOrg);
	const { news } = useSelector((state) => state.news);
	const { location } = useSelector((state) => state.location);

	console.log(singleOrg);

	const type = "yangiliklar?last_count=10";

	useEffect(() => {
		dispatch(getSingleOrg(slug));
		dispatch(getNews({ type }));
	}, [slug]);

	return (
		<section>
			<CommonSection
				title={location === "uz" ? singleOrg.name_uz : singleOrg.name_ru}
				breadCrumb={location === "uz" ? singleOrg.name_uz : singleOrg.name_ru}
			/>
			<div className="container box flex py-5 news_details">
				<div className="w-1/4 pr-5 lg:hidden">
					<div className="sticky top-0">
						<h3 className="text-2xl font-medium text-logo mb-8">{t("news")}</h3>

						<div>
							{news &&
								news?.result?.map((item, idx) => (
									<Link
										id={idx}
										className="flex min-h-[90px] pb-5 border-b border-[#ccc] mb-5"
										to={`/news/${item.id}`}
										onClick={() => window.scrollTo(0, 0)}
									>
										<img
											className="object-cover block h-[70px]"
											width={"70"}
											height={"70"}
											src={`${item?.files[0]?.file}`}
											alt="rasm"
										/>
										<div className="pl-4">
											<p className="text-logo hover:text-hover text-xs uppercase mb-2">
												{location === "uz"
													? item?.title_uz?.substring(0, 70)
													: item?.title_ru?.substring(0, 70)}
											</p>
											<div
												className="text-logo-secondary hover:text-hover text-xs"
												dangerouslySetInnerHTML={{
													__html:
														location === "uz"
															? item?.content_uz?.substring(3, 70)
															: item?.content_ru?.substring(3, 70),
												}}
											/>
										</div>
									</Link>
								))}
						</div>
					</div>
				</div>

				<div
					className="card w-3/4 lg:w-full py-8 px-16 xl:p-8 hover:shadow-2xl"
					style={{ border: "1px solid #abb8c3" }}
				>
					{/* {singleOrg?.images?.length > 0 && (
            <div className="images w-full gap-10 flex">
              <div className="right w-full">
                <Swiper
                  direction={"horizontal"}
                  pagination={{
                    clickable: true,
                  }}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
                  effect={"coverflow"}
                  grabCursor={true}
                  centeredSlides={true}
                  spaceBetween={5}
                  modules={[Pagination, EffectCoverflow, Navigation]}
                  slidesPerView={"auto"}
                  navigation={true}
                  className="mySwiper w-full"
                >
                  {singleOrg &&
                    singleOrg?.images?.map((item) => (
                      <SwiperSlide key={item.id}>
                        <img
                          src={`${item.image}`}
                          alt="img"
                          style={{
                            height: "250px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          )} */}
					<div
						className="content py-5"
						// style={{border: "1px solid rgb(171, 184, 195)"}}
					>
						<div className="p-4">
							<p
								dangerouslySetInnerHTML={{
									__html:
										location === "uz"
											? singleOrg?.organ_about_uz?.replaceAll("&nbsp;", " ")
											: singleOrg?.organ_about_ru?.replaceAll("&nbsp;", " "),
								}}
							/>
							<br />
							<div
								className="flex flex-wrap"
								dangerouslySetInnerHTML={{
									__html:
										location === "uz"
											? singleOrg?.organ_duties_uz?.replaceAll("&nbsp;", " ")
											: singleOrg?.organ_duties_ru?.replaceAll("&nbsp;", " "),
								}}
							/>
							<br />
							<div
								dangerouslySetInnerHTML={{
									__html:
										location === "uz"
											? singleOrg?.organ_activity_uz?.replaceAll("&nbsp;", " ")
											: singleOrg?.organ_activity_ru?.replaceAll("&nbsp;", " "),
								}}
							/>
						</div>
						{(singleOrg?.phone_number || singleOrg?.email) && (
							<div className="p-4">
								<h1 className="text-left">{t("contact")}</h1>
								<div className="flex gap-5 mt-5">
									{singleOrg?.phone_number &&
										singleOrg?.phone_numbers.map((item) => (
											<div key={item.id} className="flex gap-2">
												{/* <Phone className="w-[23px] object-contain h-[23px]" /> */}
												<a
													href={`tel: ${item?.number}`}
													className="text-[#007AFF]"
												>
													{item?.number}
												</a>
											</div>
										))}
									<div className="flex gap-2">
										{/* <img src={Mail} alt="rasm" className="w-[23px] object-contain h-[23px] bg-logo" /> */}
										<a
											href={`mailto: ${singleOrg?.email}`}
											className="text-[#007AFF]"
										>
											{singleOrg?.email}
										</a>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default SertificationOrgs;
