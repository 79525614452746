import { createContext, useState } from "react";

export const SystemContext = createContext()


export const SystemContextProvider = ({ children }) => {
    const [hasScrollElement, setHasScrollElement] = useState(false)

    return (
        <SystemContext.Provider value={{
            hasScrollElement,
            setHasScrollElement,
        }}>
            {children}
        </SystemContext.Provider>
    )

}