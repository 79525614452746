// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.get_key_btn{
    width: 206px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-decoration: none;
    text-align: center;
    line-height: 1.5;
    color: #0E0E4B;
    border: 2px solid #0E0E4B;
    border-radius: 68.1788px;
    padding: 10px 20px;
    margin-right: 20px;
}
.get_key_btn:hover{
    opacity: .7;
}
.get_key_btn:active{
    opacity: .6;
}
.get_key_btn--active {
    background-color: #0E0E4B;
    color: #fff;
}
.get_key_btn--active:hover {
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/pages/services/index.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,yBAAyB;IACzB,wBAAwB;IACxB,kBAAkB;IAClB,kBAAkB;AACtB;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf;AACA;IACI,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,WAAW;AACf","sourcesContent":[".get_key_btn{\n    width: 206px;\n    font-family: 'Montserrat', sans-serif;\n    font-style: normal;\n    font-weight: 700;\n    font-size: 24px;\n    text-decoration: none;\n    text-align: center;\n    line-height: 1.5;\n    color: #0E0E4B;\n    border: 2px solid #0E0E4B;\n    border-radius: 68.1788px;\n    padding: 10px 20px;\n    margin-right: 20px;\n}\n.get_key_btn:hover{\n    opacity: .7;\n}\n.get_key_btn:active{\n    opacity: .6;\n}\n.get_key_btn--active {\n    background-color: #0E0E4B;\n    color: #fff;\n}\n.get_key_btn--active:hover {\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
