// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 768px) {
    .news-card {
        width: 80%;
    }
}
@media (max-width: 600px) {
    .news-card {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/components/news/index.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;IACd;AACJ;AACA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":["@media (max-width: 768px) {\n    .news-card {\n        width: 80%;\n    }\n}\n@media (max-width: 600px) {\n    .news-card {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
